import {
  AutoAwesome,
  CalendarMonth,
  Favorite,
  FitnessCenter,
  LocalHospital,
  ModeNight,
  PermContactCalendar,
  SportsMartialArts,
  TagFaces,
  Terrain,
  WbSunny,
  WbTwilight,
} from "@mui/icons-material";
import AccessibilityNewRounded from "@mui/icons-material/AccessibilityNewRounded";
import AddReactionRounded from "@mui/icons-material/AddReactionRounded";
import Battery0BarRounded from "@mui/icons-material/Battery0BarRounded";
import BrowseGalleryRounded from "@mui/icons-material/BrowseGalleryRounded";
import DirectionsWalk from "@mui/icons-material/DirectionsWalk";
import FitnessCenterRounded from "@mui/icons-material/FitnessCenterRounded";
import HealthAndSafetyRounded from "@mui/icons-material/HealthAndSafetyRounded";
import NoAccountsRounded from "@mui/icons-material/NoAccountsRounded";
import QuestionMark from "@mui/icons-material/QuestionMark";
import RemoveCircleRounded from "@mui/icons-material/RemoveCircleRounded";
import type {
  TagGoal,
  TagObWindowsPreferred,
  TagSpecialty as TagSpecialtyType,
} from "@trainwell/types";

export type TagChallenge =
  | "getting_started"
  | "not_sure_what_to_do"
  | "not_enough_time"
  | "exercise_aversion"
  | "consistency"
  | "injury_or_illness"
  | "bored"
  | "no_energy"
  | "no_gym_access"
  | "none";

export type TagChallenge_PositiveValueCopy =
  | "improve_health"
  | "build_strength"
  | "be_consistent"
  | "look_more_fit"
  | "increase_confidence"
  | "break_through_plateaus"
  | "enjoy_exercise"
  | "overcome_injury"
  | "age_gracefully";

export const challengeData: {
  label: string;
  key: TagChallenge;
  icon: JSX.Element;
}[] = [
  {
    label: "Getting started",
    key: "getting_started",
    icon: <DirectionsWalk />,
  },
  {
    label: "Not sure what to do",
    key: "not_sure_what_to_do",
    icon: <QuestionMark />,
  },
  {
    label: "Finding time",
    key: "not_enough_time",
    icon: <BrowseGalleryRounded />,
  },
  {
    label: "Don’t like exercising",
    key: "exercise_aversion",
    icon: <AccessibilityNewRounded />,
  },
  {
    label: "Consistency",
    key: "consistency",
    icon: <FitnessCenterRounded />,
  },
  {
    label: "Injury/health issue",
    key: "injury_or_illness",
    icon: <HealthAndSafetyRounded />,
  },
  {
    label: "Boredom",
    key: "bored",
    icon: <AddReactionRounded />,
  },
  {
    label: "Not enough energy",
    key: "no_energy",
    icon: <Battery0BarRounded />,
  },
  {
    label: "No gym or equipment",
    key: "no_gym_access",
    icon: <NoAccountsRounded />,
  },
  {
    label: "Nothing specific",
    key: "none",
    icon: <RemoveCircleRounded />,
  },
];

export const goalCopy: {
  label: string;
  key: TagGoal;
  specialtyKey?: TagSpecialtyType;
  icon: JSX.Element;
}[] = [
  {
    label: "Improve health",
    key: "improve_health",
    specialtyKey: "general_health",
    icon: <Favorite />,
  },
  {
    label: "Build strength",
    specialtyKey: "strength",
    key: "build_strength",
    icon: <FitnessCenter />,
  },
  {
    label: "Be consistent",
    key: "be_consistent",
    icon: <CalendarMonth />,
  },
  {
    label: "Look more fit",
    key: "look_more_fit",
    icon: <TagFaces />,
  },
  {
    label: "Increase confidence",
    key: "increase_confidence",
    icon: <AutoAwesome />,
  },
  {
    label: "Overcome plateaus",
    key: "break_through_plateaus",
    icon: <Terrain />,
  },
  {
    label: "Enjoy exercise",
    key: "enjoy_exercise",
    icon: <SportsMartialArts />,
  },
  {
    label: "Recover from injury",
    specialtyKey: "mobility_flexibility",
    key: "overcome_injury",
    icon: <LocalHospital />,
  },
  {
    label: "Age gracefully",
    key: "age_gracefully",
    icon: <PermContactCalendar />,
  },
];

export const obWindowsCopy: {
  label: string;
  key: TagObWindowsPreferred;
  icon: JSX.Element;
}[] = [
  {
    label: "Morning",
    key: "morning",
    icon: <WbTwilight />,
  },
  {
    label: "Afternoon",
    key: "afternoon",
    icon: <WbSunny />,
  },
  {
    label: "Evening",
    key: "evening",
    icon: <ModeNight />,
  },
  {
    label: "No preference",
    key: "none",
    icon: <RemoveCircleRounded />,
  },
];
