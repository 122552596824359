import { LoadingPage } from "@trainwell/ui";
import { Suspense, createContext, lazy, useContext, useEffect } from "react";
import type { NavigateFunction } from "react-router-dom";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getCookie } from "src/lib/cookie";
import { event } from "src/lib/fpixel";
import {
  addTestToBA,
  addVisitedPage,
  fetchActiveTestIds,
  getInfluencerBySourceId,
  selectActiveTestIdsStatus,
  selectActiveTests,
  selectSessionStatus,
  setBrand,
  setRefferalInfo,
  setSessionID,
  setSourceID,
  startSession,
  trackEvent,
} from "src/slices/analyticsSlice";
import { setClientID, setPairInfuencerUserId } from "src/slices/clientSlice";
import { getOneCoach } from "src/slices/coachSlice";
import {
  forceFreemiumPaths,
  setPagePathStatus,
} from "src/slices/navigationSlice";
import {
  setForceNoCard,
  setIsFree,
  setTrialDays,
} from "src/slices/paymentSlice";
import { fetchSharedWorkouts } from "src/slices/sharedWorkoutsSlice";
import { registerQueryParams, trackPageView } from "../lib/btracking";
import DownloadFreemiumPage from "./freemium/DownloadFreemiumPage";
import ErrorDialog from "./misc/ErrorDialog";
import AddressContainer from "./pages/Address/AddressContainer";
import Done from "./pages/Done";
import FindingCoach from "./pages/FindingCoach";
import Gender from "./pages/Gender";
import HeightWeightContainer from "./pages/HeightWeight/HeightWeightContainer";
import Layout from "./pages/Layout/Layout";
import PayPageContainer from "./pages/Pay/PayPageContainer";
import Phone from "./pages/Phone";
import PlanContainer from "./pages/Plan/PlanContainer";
import RegisterPageContainer from "./pages/Register/RegisterPageContainer";
import ScheduleCall from "./pages/ScheduleCall";
import SelectCoach from "./pages/SelectCoach/SelectCoach";
import SmartWatch from "./pages/SmartWatch";
import SnippetContainer from "./pages/Snippet/SnippetContainer";
import Source from "./pages/Source";
import SplashPageContainer from "./pages/SplashPage/SplashPageContainer";
import TagCommunicationStyle from "./pages/Tags/TagCommunicationStyle";
import TagExperiencePage from "./pages/Tags/TagExperiencePage";
import TagGoals from "./pages/Tags/TagGoals";
import TagIdentityPage from "./pages/Tags/TagIdentityPage";
import TagObWindowsPreferred from "./pages/Tags/TagObWindowsPreferred";
import ValuePageContainer_Testimonials from "./pages/Value/ValuePageContainer_Testimonials";
import WeightSystem from "./pages/WeightSystem";
import WorkoutEquipment from "./pages/WorkoutEquipment";
import WorkoutFrequency from "./pages/WorkoutFrequency";

const SetupFreemiumPage = lazy(() => import("./freemium/SetupFreemiumPage"));

export interface SurveyContextType {
  nextPage: () => void;
  previousPage: () => void;
  navigate: NavigateFunction;
}

export const SurveyContext = createContext<SurveyContextType>({
  nextPage: () => {},
  previousPage: () => {},
  navigate: () => {},
});

export default function Survey() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const sessionID = useAppSelector((state) => state.analytics.sessionID);
  const visitedPages = useAppSelector((state) => state.analytics.visitedPages);
  const pagePaths = useAppSelector((state) => state.navigation.pagePaths);

  const forcedTrainer = useAppSelector((state) => state.coach.forcedTrainer);
  const forcedTrainerId = useAppSelector(
    (state) => state.coach.forcedTrainerId,
  );
  const getOneCoachStatus = useAppSelector(
    (state) => state.coach.getOneCoachStatus,
  );

  const pageIndex = pagePaths.findIndex(
    (path) => path.path === location.pathname,
  );
  const sessionStatus = useAppSelector(selectSessionStatus);
  const activeTestsIdsStatus = useAppSelector(selectActiveTestIdsStatus);

  const activeTestsIds = useAppSelector(selectActiveTests);
  const sharedWorkouts = useAppSelector(
    (state) => state.sharedWorkouts.sharedWorkouts,
  );
  const sharedWorkoutsStatus = useAppSelector(
    (state) => state.sharedWorkouts.status,
  );
  const [urlSearchParams] = useSearchParams();
  const querySharedWorkoutId = urlSearchParams.get("shared_workouts_id");

  useEffect(() => {
    if (sharedWorkoutsStatus === "idle" && querySharedWorkoutId) {
      dispatch(fetchSharedWorkouts(querySharedWorkoutId));
    }
  }, [sharedWorkoutsStatus, querySharedWorkoutId]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const test_user =
      import.meta.env.VITE_ENV === "DEV" ||
      urlParams.get("test_user")?.toLocaleLowerCase() === "true";

    if (
      !sessionID &&
      !test_user &&
      !(
        location.pathname === "/" ||
        location.pathname === "/tags-challenge" ||
        location.pathname === "/register"
      )
    ) {
      navigate("/" + location.search);
    }
  }, [sessionID]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isForcedTrainerFlow = forcedTrainerId !== undefined;
    const isLeadFlow =
      urlParams.get("referral_content") === "referral_lead" &&
      isForcedTrainerFlow;

    dispatch(
      setPagePathStatus({
        path: "/tags-challenge",
        status: isForcedTrainerFlow ? "inactive" : "active",
      }),
    );
    dispatch(
      setPagePathStatus({
        path: "/value",
        status: isForcedTrainerFlow ? "inactive" : "active",
      }),
    );
    dispatch(
      setPagePathStatus({
        path: "/tags1",
        status: isForcedTrainerFlow ? "inactive" : "active",
      }),
    );
    dispatch(
      setPagePathStatus({
        path: "/tags2",
        status: isForcedTrainerFlow ? "inactive" : "active",
      }),
    );
    dispatch(
      setPagePathStatus({
        path: "/snippet1",
        status: isForcedTrainerFlow ? "inactive" : "active",
      }),
    );
    dispatch(
      setPagePathStatus({
        path: "/tags3",
        status: isForcedTrainerFlow ? "inactive" : "active",
      }),
    );
    dispatch(
      setPagePathStatus({
        path: "/tags4",
        status: isForcedTrainerFlow ? "inactive" : "active",
      }),
    );
    dispatch(
      setPagePathStatus({
        path: "/tags5",
        status: isLeadFlow ? "inactive" : "active",
      }),
    );
    dispatch(
      setPagePathStatus({
        path: "/frequency",
        status: isForcedTrainerFlow ? "inactive" : "active",
      }),
    );
    dispatch(
      setPagePathStatus({
        path: "/equipment",
        status: isForcedTrainerFlow ? "inactive" : "active",
      }),
    );
    dispatch(
      setPagePathStatus({
        path: "/transition",
        status: isForcedTrainerFlow ? "inactive" : "active",
      }),
    );
    dispatch(
      setPagePathStatus({
        path: "/finding-coach",
        status: isForcedTrainerFlow ? "inactive" : "active",
      }),
    );
    dispatch(
      setPagePathStatus({
        path: "/schedule-call",
        status: isLeadFlow ? "inactive" : "active",
      }),
    );

    // Fetch Trainer if needed.
    if (
      forcedTrainerId &&
      (getOneCoachStatus === "idle" ||
        getOneCoachStatus === "failed" ||
        forcedTrainer?.trainer_id !== forcedTrainerId)
    ) {
      dispatch(getOneCoach({ trainerId: forcedTrainerId }));
    }
  }, [
    forcedTrainerId,
    forcedTrainer?.trainer_id,
    getOneCoachStatus,
    pagePaths,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    if (
      activeTestsIds.filter((test) => test.startsWith("sr_offer_v3_applied"))
        .length > 0
    ) {
      dispatch(setTrialDays(14));
    }
  }, [activeTestsIds, dispatch]);

  useEffect(() => {
    if (sharedWorkouts?.id) {
      // This is a freemium client signing up
      // They get specific paths

      dispatch(forceFreemiumPaths());
    }
  }, [dispatch, sharedWorkouts?.id]);

  // Sends activeTestsIds to behavior analytics after they have loaded
  useEffect(
    function trackActiveTests() {
      if (activeTestsIdsStatus === "succeeded") dispatch(addTestToBA());
    },
    [activeTestsIdsStatus],
  );

  useEffect(() => {
    if (sessionID) {
      let firstVisit = false;

      if (visitedPages.includes(location.pathname)) {
        console.log("Already visited page");
        firstVisit = false;
      } else {
        console.log("First page view");
        firstVisit = true;
      }

      dispatch(addVisitedPage(location.pathname));

      trackPageView();

      dispatch(
        trackEvent({
          event_type: "page_view",
          event_content: {
            path: location.pathname,
            first_view: firstVisit,
            queryString: location.search ? location.search : undefined,
            hostname: window.location.hostname,
          },
        }),
      );
    }
  }, [dispatch, location, sessionID]);

  useEffect(() => {
    async function createSession() {
      const storedUserID = getCookie("userID");

      let customSessionStorage: Storage | undefined = undefined;

      try {
        customSessionStorage = sessionStorage;
      } catch {
        console.log("Access to session storage not allowed");
      }

      let storedSessionID = customSessionStorage?.getItem("sessionID");

      if (storedUserID) {
        console.log("Found user_id: " + storedUserID);
      }

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const user_id = urlParams.get("user_id");
      const utm_medium = urlParams.get("utm_medium");
      const utm_source = urlParams.get("utm_source");
      const utm_campaign = urlParams.get("utm_campaign");
      const utm_content = urlParams.get("utm_content");
      const source_id = urlParams.get("source");
      const param_session_id = urlParams.get("session_id");
      const tests = urlParams.getAll("test");
      const debugTest = urlParams.get("debugtest");
      const test_user =
        import.meta.env.VITE_ENV === "DEV" ||
        urlParams.get("test_user")?.toLocaleLowerCase() === "true";
      const param_brand = urlParams.get("brand");
      const brand_user_id = urlParams.get("brand_user_id");
      const referralId = urlParams.get("referral_id");
      const referralCode = urlParams.get("referral_code");
      const referralCTAId = urlParams.get("referral_cta_id");
      const referrerUserId = urlParams.get("referrer_user_id");
      const referralCampaign = urlParams.get("referral_campaign");
      const paymentType = urlParams.get("payment_type");
      const code_id = urlParams.get("code");
      const trainerId = urlParams.get("trainer_id");
      const pairInfluencerUserId = urlParams.get("pair_influencer_user_id");

      const isDebugger =
        urlParams.get("debugger")?.toLocaleLowerCase() === "true";

      const userId = user_id
        ? user_id
        : storedUserID
          ? storedUserID
          : undefined;

      if (userId) {
        console.log("Found user_id: " + userId);
      }

      storedSessionID = param_session_id ?? storedSessionID;

      if (storedSessionID) {
        console.log("Found session_id: " + storedSessionID);
      }

      if (pairInfluencerUserId) {
        dispatch(setPairInfuencerUserId(pairInfluencerUserId));
      }

      if (source_id) {
        dispatch(setSourceID(source_id));
      }

      if (paymentType === "free") {
        dispatch(setIsFree(true));
      } else if (paymentType === "no_card") {
        dispatch(setForceNoCard(true));
      }

      registerQueryParams();

      dispatch(
        setRefferalInfo({
          referralId: referralId ?? undefined,
          referralCode: referralCode ?? undefined,
          referralCTAId: referralCTAId ?? undefined,
          referrerUserId: referrerUserId ?? undefined,
          referralCampaign: referralCampaign ?? undefined,
        }),
      );

      if (referralCTAId || referralCode || referralId) {
        if (referralCampaign && referralCampaign === "referral_v3.0") {
          dispatch(setTrialDays(14));
        } else {
          dispatch(setTrialDays(30));
        }
      }

      if (param_brand === "af") {
        dispatch(setBrand("af"));
      } else if (param_brand === "echelon") {
        dispatch(setBrand("echelon"));
      } else if (param_brand === "gf") {
        dispatch(setBrand("gf"));
        dispatch(setIsFree(true));
      }

      const forceTestArray: string[] = [];

      if (tests && tests.length > 0) {
        console.log(`Test: forcing array of tests`, tests);

        forceTestArray.push(...tests);
      } else {
        console.log(`Test: no tests found in query string`);
      }

      if (source_id === "goalguys" && utm_content === "dec2022") {
        forceTestArray.push("goal_guys_offer");
      }

      if (trainerId || code_id) forceTestArray.push("NOSUBSCRIPTIONTESTING");

      if (userId && storedSessionID) {
        // Found a user id and session

        dispatch(setSessionID(storedSessionID));
        dispatch(setClientID(userId));

        await dispatch(
          fetchActiveTestIds({
            forceTestArray,
            debugTest: debugTest ?? undefined,
          }),
        );
        await dispatch(getInfluencerBySourceId(source_id));
      } else {
        // This will...
        // create a session_start event
        // create a client account if needed
        // assign a/b tests
        // return all of that data and save it here to be used by the front-end

        if (
          !test_user &&
          !(
            location.pathname === "/" ||
            location.pathname === "/tags-challenge" ||
            location.pathname === "/register"
          )
        ) {
          navigate("/" + location.search);
        }

        await dispatch(
          startSession({
            data: {
              userId: userId,
              utmContent: utm_content ?? undefined,
              utmMedium: utm_medium ?? undefined,
              utmSource: utm_source ?? undefined,
              utmCampaign: utm_campaign ?? undefined,
              sourceId: source_id ?? undefined,
              referrer: document.referrer,
              referralId: referralId ?? undefined,
              referralCode: referralCode ?? undefined,
              referralCTAId: referralCTAId ?? undefined,
              referrerUserId: referrerUserId ?? undefined,
              referralCampaign: referralCampaign ?? undefined,
              forceTest: forceTestArray.length > 0 ? forceTestArray : undefined,
              debugTest: debugTest ?? undefined,
              testUser: test_user ?? undefined,
              brandUserId: brand_user_id ?? undefined,
              isDebugger: isDebugger,
            },
            trainerId: trainerId ?? undefined,
          }),
        );
      }
    }

    createSession();
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    event("AddToCart");
  }, []);

  function nextPage() {
    let jumpIndex = pageIndex + 1;
    while (pagePaths[jumpIndex].active === false) {
      jumpIndex++;
    }
    navigate(pagePaths[jumpIndex].path + location.search);
  }

  function previousPage() {
    let jumpIndex = pageIndex - 1;
    //TODO: add case here for OR pagePath.shouldskipback and eliminate the need for the if else statement below
    while (pagePaths[jumpIndex].active === false) {
      jumpIndex--;
    }
    if (pagePaths[jumpIndex].shouldSkipBack) {
      // let isJumpBack = true;
      // while (isJumpBack) {
      //   if (!pagePaths[jumpIndex].shouldSkipBack) {
      //     isJumpBack = false;
      //     break;
      //   }
      //   jumpIndex--;
      // }
      navigate(pagePaths[jumpIndex - 1].path + location.search);
    } else {
      navigate(pagePaths[jumpIndex].path + location.search);
    }
  }

  if (sessionStatus === "failed" || activeTestsIdsStatus === "failed") {
    return <ErrorDialog />;
  }

  return (
    <SurveyContext.Provider
      value={{
        nextPage: nextPage,
        previousPage: previousPage,
        navigate: navigate,
      }}
    >
      <Layout>
        <Suspense fallback={<LoadingPage message="Loading page" />}>
          <Routes>
            <Route path="done" element={<Done />} />
            <Route path="source" element={<Source />} />
            <Route path="address" element={<AddressContainer />} />
            <Route path="schedule-call" element={<ScheduleCall />} />
            <Route path="pay" element={<PayPageContainer />} />
            <Route path="register" element={<RegisterPageContainer />} />
            <Route path="plan" element={<PlanContainer />} />
            <Route path="select-coach" element={<SelectCoach />} />
            <Route path="finding-coach" element={<FindingCoach />} />
            <Route path="smart-watch" element={<SmartWatch />} />
            <Route path="phone" element={<Phone />} />
            <Route path="gender" element={<Gender />} />
            <Route path="height-weight" element={<HeightWeightContainer />} />
            <Route path="weight-system" element={<WeightSystem />} />
            <Route path="equipment" element={<WorkoutEquipment />} />
            <Route path="frequency" element={<WorkoutFrequency />} />
            <Route path="tags3" element={<TagCommunicationStyle />} />
            <Route path="tags4" element={<TagObWindowsPreferred />} />
            <Route path="tags1" element={<TagIdentityPage />} />
            <Route path="snippet1" element={<SnippetContainer />} />
            <Route path="tags2" element={<TagExperiencePage />} />
            <Route path="value" element={<ValuePageContainer_Testimonials />} />
            <Route path="tags-challenge" element={<TagGoals />} />
            <Route path="setup-freemium" element={<SetupFreemiumPage />} />
            <Route path="finish-freemium" element={<DownloadFreemiumPage />} />
            <Route index element={<SplashPageContainer />} />
          </Routes>
        </Suspense>
      </Layout>
    </SurveyContext.Provider>
  );
}

export const useSurvey = (): SurveyContextType => useContext(SurveyContext);
