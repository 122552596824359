import { Box, Button, Container, Typography } from "@mui/material";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { track } from "src/lib/btracking";
import { submitUserSource } from "src/slices/analyticsSlice";

export default function Source() {
  const dispatch = useAppDispatch();
  const survey = useSurvey();
  const brand = useAppSelector((state) => state.analytics.brand);

  let possibleSources = [
    "Reddit",
    "Friend or family",
    "Google",
    "YouTube",
    "Facebook",
    "Podcast",
    "App Store",
    "Other",
  ];

  if (brand === "af") {
    possibleSources = [
      "Email",
      "Gym physical marketing",
      "Staff recommendation",
      "Other",
    ];
  }

  function selectSource(source: string) {
    dispatch(submitUserSource(source));
    track("Source Select", { source: source });
    survey.nextPage();
  }

  return (
    <Container maxWidth="xs">
      <Typography variant="subtitle1" sx={{ mb: 4 }}>
        Last question, where did you hear about us?
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        {possibleSources.map((source) => {
          return (
            <Button
              key={source}
              sx={{
                mb: 2,
              }}
              onClick={() => {
                selectSource(source);
              }}
            >
              {source}
            </Button>
          );
        })}
      </Box>
    </Container>
  );
}
