import { Box, Button, Container, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { registerProp } from "src/lib/btracking";
import { resetSelectedTrainer, selectTrainerName } from "src/slices/coachSlice";
import { setPagePathOptions } from "src/slices/navigationSlice";
import { setChosenOS, setOwnsSmartWatch } from "src/slices/surveySlice";

export default function Phone() {
  const dispatch = useAppDispatch();
  const survey = useSurvey();
  const selectedTrainer = useAppSelector(selectTrainerName);

  useEffect(() => {
    if (selectedTrainer) {
      dispatch(resetSelectedTrainer());
    }
  }, []);

  const onClick = (os: "android" | "ios") => {
    registerProp({ mobileOS: os });
    dispatch(setChosenOS(os));
    if (os === "android") {
      dispatch(setOwnsSmartWatch(false));
      dispatch(
        setPagePathOptions({
          path: "/smart-watch",
          options: {
            shouldSkipBack: true,
            active: false,
          },
        }),
      );
    } else {
      dispatch(
        setPagePathOptions({
          path: "/smart-watch",
          options: {
            shouldSkipBack: false,
            active: true,
          },
        }),
      );
    }
    survey.nextPage();
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h3" sx={{ mb: 4 }}>
        What type of phone do you use?
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <Button
          sx={{
            mb: 2,
          }}
          onClick={() => onClick("ios")}
        >
          iPhone
        </Button>
        <Button
          sx={{
            mb: 2,
          }}
          onClick={() => onClick("android")}
        >
          Android
        </Button>
      </Box>
    </Container>
  );
}
