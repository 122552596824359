import LockIcon from "@mui/icons-material/Lock";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import type { SubscriptionGroupOption } from "@trainwell/types";
import { useAddPaymentMethod } from "@trainwell/ui";
import { useEffect, useState } from "react";
import { useSurvey } from "src/components/Survey";
import PhoneNumberInput from "src/components/misc/PhoneNumberInput";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { useUrlParams } from "src/hooks/useUrlParams";
import { event } from "src/lib/fpixel";
import { logGTagConversion } from "src/lib/gtag";
import { sha1Hash } from "src/lib/misc";
import { api } from "src/lib/trainwellApi";
import { selectActiveTests } from "src/slices/analyticsSlice";
import {
  resetPaymentAttempt,
  selectIsNoCard,
  setIsInterestedInFsaHsa,
  setSelectedSubOption,
  setStripeCoupon,
  setupFreeClient,
  setupNoCardClient,
  subscribe,
} from "src/slices/paymentSlice";
import { setPhoneNumber } from "src/slices/surveySlice";
import CTAButton from "../Layout/CTAButton";

export default function Pay({
  subOptions,
}: {
  subOptions: SubscriptionGroupOption[];
}) {
  const dispatch = useAppDispatch();
  const survey = useSurvey();
  const submitPaymentStatus = useAppSelector(
    (state) => state.payment.submitPaymentStatus,
  );
  const selectedPlan = useAppSelector(
    (state) => state.payment.selectedSubOption,
  );
  const isOverrideSubs = useAppSelector(
    (state) => state.payment.overrideSubscriptionOptions,
  )
    ? true
    : false;
  const phoneNumber = useAppSelector((state) => state.survey.phoneNumber);
  const [localPhoneNumber, setLocalPhoneNumber] = useState(phoneNumber ?? "");
  const email = useAppSelector((state) => state.survey.email);
  const clientFullName = useAppSelector(
    (state) => state.survey.firstName + " " + state.survey.lastName,
  );
  const isNoCard = useAppSelector(selectIsNoCard);
  const isFree = useAppSelector((state) => state.payment.isFree);
  const brand = useAppSelector((state) => state.analytics.brand);
  const userId = useAppSelector((state) => state.client.clientID);
  const selectedTrainer = useAppSelector(
    (state) => state.coach.selectedTrainer,
  );
  const activeTestsIds = useAppSelector(selectActiveTests);
  const referralCampaign = useAppSelector(
    (state) => state.analytics.referralCampaign,
  );

  const {
    addPaymentMethod,
    paymentErrorMessage,
    validationMessage,
    submitting: submittingPaymentMethod,
  } = useAddPaymentMethod({
    api: api,
    userId: userId ?? "",
    onSuccess: () => {
      dispatch(subscribe());
    },
  });

  const stripe = useStripe();
  const elements = useElements();

  const [gymLocation, setGymLocation] = useState<string>();

  const isSRO50 = activeTestsIds.includes("sr_offer_v3_applied_a");

  //Check for each known coupon type in coupon URL param (support for single value and array of coupons)
  const { coupon } = useUrlParams("coupon");
  const monthOff =
    coupon === "Eylkptwh" ||
    (Array.isArray(coupon) && coupon.includes("Eylkptwh"));
  const threeMonthsOff =
    coupon === "md9OwRDm" ||
    (Array.isArray(coupon) && coupon.includes("md9OwRDm"));
  const twelveMonthsOff =
    coupon === "gHc3TYGR" ||
    (Array.isArray(coupon) && coupon.includes("gHc3TYGR"));
  const twentyPercentOff =
    coupon === "1J87JxZV" ||
    (Array.isArray(coupon) && coupon.includes("1J87JxZV"));
  const fiftyPercentOffReferral = referralCampaign === "referral_v3.0";
  const fiftyPercentOffSR =
    coupon === "6WoVpy97" ||
    (Array.isArray(coupon) && coupon.includes("6WoVpy97")); //This is the NEW way of doing SR so we don't need to use a sketchy AB test
  const fiftyDollarsOffSR =
    coupon === "ckh5c7xM" ||
    (Array.isArray(coupon) && coupon.includes("ckh5c7xM"));
  const amountOff25 =
    coupon === "btc0eabd" ||
    (Array.isArray(coupon) && coupon.includes("btc0eabd"));

  // Ways to discount: 1 month fully free (coupon), 50% smart recover (AB test), 20% offer (coupon)
  // Killing: three months off (coupon), 60% SR

  let baseMonthlyPrice = 0;
  let recurringPrice = 0;
  let discountAmount: number | undefined = undefined;

  if (!isOverrideSubs) {
    baseMonthlyPrice = subOptions.reduce((acc, option) => {
      return option.interval === "month" && option.interval_count === 1
        ? option.monthly_price
        : acc;
    }, 99);

    recurringPrice =
      subOptions[selectedPlan].interval === "month" &&
      subOptions[selectedPlan].interval_count === 1
        ? subOptions[selectedPlan].monthly_price
        : subOptions[selectedPlan].interval === "month"
          ? subOptions[selectedPlan].monthly_price *
            subOptions[selectedPlan].interval_count
          : subOptions[selectedPlan].monthly_price * 12;

    //If you're in a given discount group, calculate that discount
    if (monthOff) {
      discountAmount = baseMonthlyPrice;
    } else if (isSRO50) {
      discountAmount = baseMonthlyPrice * 0.5;
    } else if (twentyPercentOff) {
      discountAmount = baseMonthlyPrice * 0.2;
    } else if (fiftyPercentOffReferral) {
      discountAmount = baseMonthlyPrice * 0.5;
    } else if (fiftyPercentOffSR) {
      discountAmount = baseMonthlyPrice * 0.5;
    } else if (threeMonthsOff) {
      discountAmount = baseMonthlyPrice * 3.0;
    } else if (twelveMonthsOff) {
      discountAmount = baseMonthlyPrice * 12.0;
    } else if (fiftyDollarsOffSR) {
      discountAmount = 50.0;
    } else if (amountOff25) {
      discountAmount = 25;
    }
  }

  useEffect(() => {
    event("InitiateCheckout");
  }, []);

  useEffect(() => {
    if (submitPaymentStatus === "succeeded") {
      const price = subOptions[selectedPlan].monthly_price;

      event("Subscribe", {
        currency: "USD",
        value: price,
        predicted_ltv: price && price * 10,
      });

      // @ts-expect-error
      window.rdt("track", "Purchase", {
        currency: "USD",
        itemCount: 1,
        value: price,
      });

      logGTagConversion({
        conversionKey: "PURCHASE",
        value: recurringPrice,
        currency: "USD",
        transaction_id: "",
        event_callback: () => undefined,
      });

      // @ts-expect-error
      window.ire(
        "trackConversion",
        44925,
        {
          orderId: userId ?? "",
          customProfileId: userId ?? "",
          customerId: userId ?? "",
          customerEmail: email ? sha1Hash(email) : "",
        },
        {
          verifySiteDefinitionMatch: true,
        },
      );

      if ("podscribe" in window) {
        try {
          const encoder = new TextEncoder();
          const data = encoder.encode(email);

          crypto.subtle.digest("SHA-256", data).then((hashBuffer) => {
            const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
            const hashHex = hashArray
              .map((b) => b.toString(16).padStart(2, "0"))
              .join(""); // convert bytes to hex string

            console.log("Hashed email:", hashHex);

            // @ts-expect-error
            window.podscribe("purchase", {
              value: recurringPrice,
              discount_code: coupon,
              hashed_email: hashHex,
              is_subscription: true,
              currency: "usd",
            });
          });
        } catch (error) {
          console.error(error);
        }
      }

      survey.nextPage();
    }
  }, [submitPaymentStatus, dispatch]);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    dispatch(setPhoneNumber(localPhoneNumber));

    //If in SR test, apply a flat $49.50 discount (50% of base $99 monthly price)
    if (isSRO50) {
      dispatch(setStripeCoupon("6WoVpy97"));
    }

    //If you're in any other discount group, apply that coupon(s)
    else if (coupon) {
      if (typeof coupon === "string") {
        dispatch(setStripeCoupon(coupon));
      } else if (Array.isArray(coupon)) {
        for (const c of coupon) {
          dispatch(setStripeCoupon(c));
        }
      }
    }

    if (isFree) {
      dispatch(setupFreeClient());
    } else if (isNoCard) {
      dispatch(
        setupNoCardClient({
          gymLocation: gymLocation,
        }),
      );
    } else {
      addPaymentMethod(true);
    }
  }

  if ((!stripe || !elements) && !(isFree || isNoCard)) {
    return null;
  }

  return (
    <>
      <Container maxWidth="sm" sx={{ pb: 4 }}>
        <Typography
          variant="h1"
          sx={{
            mb: activeTestsIds.includes("dec_trial_reminder_treatment")
              ? { xs: 2, sm: 2 }
              : { xs: 2, sm: 4 },
            textAlign: "center",
          }}
        >
          Complete Registration
        </Typography>
        {activeTestsIds.includes("dec_trial_reminder_treatment") && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                borderRadius: 4,
                px: 2,
                background: "#F4F2F8",
                color: "#443564",
                textAlign: "center",
              }}
            >
              <Typography fontWeight={"bold"} fontSize={{ xs: 15, sm: 17 }}>
                We&apos;ll email you the day before your free trial ends.
              </Typography>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            mb: 4,
          }}
        ></Box>
        {!isFree && !isNoCard && (
          <>
            <Typography sx={{ mb: 1, fontWeight: "bold" }}>
              Price After Trial
            </Typography>
            <Divider />
            {isNoCard ? (
              <Typography textAlign="center" sx={{ my: 4 }}>
                After your trial ends you can enter a card and pay $
                {subOptions.length >= 2 ? subOptions[1].monthly_price : "99"}
                /mo to continue using trainwell.
              </Typography>
            ) : (
              <>
                {subOptions.length > 0 &&
                  subOptions.map((subOption, i) => {
                    const { title, monthly_price, details } = subOption;

                    return (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          my: 2,
                        }}
                        key={i}
                      >
                        <Box display="flex" alignItems="center">
                          <Radio
                            checked={selectedPlan === i}
                            onChange={() => {
                              dispatch(setSelectedSubOption(i));
                            }}
                            value={i}
                          />
                          <Typography style={{ fontWeight: 500 }}>
                            {title}
                          </Typography>
                        </Box>
                        <Box sx={{ textAlign: "end" }}>
                          <Typography sx={{ mb: -0.5 }}>
                            ${`${monthly_price}/mo`}
                          </Typography>
                          <Typography variant="caption">{details}</Typography>
                        </Box>
                      </Box>
                    );
                  })}
                <Typography variant="caption">
                  All prices in United States Dollars (USD)
                </Typography>
              </>
            )}
            {discountAmount && !isOverrideSubs && (
              <>
                <Divider sx={{ mb: 1 }} />
                <Typography
                  sx={{
                    textAlign: "right",
                    fontWeight: "bold",
                    color: "green",
                  }}
                >
                  Discount Applied: ${discountAmount.toFixed(2)} off first
                  payment
                </Typography>
                {activeTestsIds.includes("dec_price_after_trial_treatment") && (
                  <Typography sx={{ textAlign: "right" }}>
                    Due after free trial:{" "}
                    <span
                      style={{
                        textDecoration: "line-through",
                        opacity: 0.5,
                        fontStyle: "italic",
                        color: "text.secondary",
                      }}
                    >
                      ${recurringPrice}
                    </span>{" "}
                    ${(recurringPrice - discountAmount).toFixed(2)}
                  </Typography>
                )}
              </>
            )}
            <Divider
              sx={{ opacity: discountAmount ? 0.0 : 1.0, mb: 4, mt: 1 }}
            />
          </>
        )}
        <form onSubmit={handleSubmit}>
          <>
            <Box
              sx={{
                mb: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography sx={{ fontWeight: "bold" }}>
                  Mobile Phone Number
                </Typography>
                <Typography sx={{ mb: 1 }}>
                  You&apos;ll use this number to log into the trainwell app.
                </Typography>
              </Box>
              <Tooltip title={"Secured by SSL"}>
                <LockIcon />
              </Tooltip>
            </Box>
            <PhoneNumberInput
              defaultCountry="us"
              value={localPhoneNumber}
              preferredCountries={["us", "gb"]}
              disableAreaCodes={true}
              onChange={(e: any) => {
                setLocalPhoneNumber(e.replace(/[^0-9+]/g, ""));
              }}
              disabled={submittingPaymentMethod}
            />
            {!isFree && !isNoCard && (
              <>
                <Box
                  sx={{
                    mb: 2,
                    mt: 4,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ mr: 2 }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Credit Card
                    </Typography>
                    <Typography sx={{ mb: 1 }}>
                      You can cancel your membership at anytime from the
                      trainwell app.
                    </Typography>
                  </Box>
                  <Tooltip title={"Payment secured by Stripe"}>
                    <LockIcon />
                  </Tooltip>
                </Box>
                <PaymentElement
                  options={{
                    layout: "auto",
                    readOnly: submittingPaymentMethod,
                    defaultValues: {
                      billingDetails: {
                        email: email,
                        phone: localPhoneNumber,
                        name: clientFullName,
                      },
                    },
                    terms: {
                      card: "never",
                      applePay: "never",
                      googlePay: "never",
                    },
                  }}
                />
                {paymentErrorMessage && (
                  <Typography sx={{ fontSize: 16 }} color={"error"}>
                    {paymentErrorMessage}
                  </Typography>
                )}
                {validationMessage && (
                  <Typography sx={{ fontSize: 16 }} color={"error"}>
                    {validationMessage}
                  </Typography>
                )}
              </>
            )}
          </>
          {activeTestsIds.includes("apr_fsa_treatment") && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "16px",
                marginBottom: "8px",
              }}
            >
              <Checkbox
                onChange={(e) => {
                  dispatch(setIsInterestedInFsaHsa(e.target.checked));
                }}
              />
              <Typography>
                Email me information about FSA/HSA reimbursement.
              </Typography>
            </Box>
          )}
          {brand === "af" && (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="demo-simple-select-label">
                Gym location
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={gymLocation}
                label="Gym location"
                onChange={(event) => {
                  setGymLocation(event.target.value as string);
                }}
              >
                <MenuItem value={4466}>Robert, LA</MenuItem>
                <MenuItem value={234}>Excelsior Springs, MO </MenuItem>
                <MenuItem value={5041}>Hannibal, MO </MenuItem>
                <MenuItem value={1481}>Chillicothe, MO </MenuItem>
                <MenuItem value={2576}>Kirksville, MO </MenuItem>
                <MenuItem value={4410}>Sanford, NC</MenuItem>
                <MenuItem value={2364}>Guthrie, OK </MenuItem>
                <MenuItem value={2250}>Center, TX</MenuItem>
                <MenuItem value={2665}>Hallsville, TX</MenuItem>
                <MenuItem value={3328}>Sandy, UT</MenuItem>
                <MenuItem value={4426}>Midvale, UT</MenuItem>
                <MenuItem value={1705}>Bristol, VA</MenuItem>
              </Select>
            </FormControl>
          )}
          <CTAButton
            submit
            CTAText={"Try for $0.00"}
            disabled={
              localPhoneNumber?.length < 5 ||
              (!stripe && !isFree && !isNoCard) ||
              (brand === "af" && !gymLocation)
            }
            loading={
              submitPaymentStatus === "loading" || submittingPaymentMethod
            }
          />
        </form>
        <Typography textAlign="center" sx={{ mb: 1 }}>
          By subscribing, you agree to our{" "}
          <Link href="https://www.trainwell.net/terms-and-conditions">
            Terms &amp; Conditions
          </Link>{" "}
          and consent to receive
          {[
            // Lmao, I hate this
            // TODO: remove this when "Call OB clients" test is done
            "2b671a6c-8b24-4d6e-9ea8-3a4dfb7558fa",
            "c892245f-fbfa-492d-8f56-6c551d836d53",
            "2eb7bf67-56be-495a-b07c-137dcd8a6e80",
            "4bf63601-f267-467b-a2a4-e1cf02a6d3e0",
            "c07c49cd-f8a1-4c96-a0c4-76bb2c044c54",
            "fb30ad01-75a3-4986-9298-c9d074675942",
            "f234aafe-60e8-4a5c-9f1f-2fda38f6e56e",
            "2c92f37a-5a00-4e09-a63c-c45bbcf0807f",
            "5a192a24-8935-4968-bf1d-bb36c62dc9da",
            "5276c178-aec4-4546-a53f-f78f71ec0fb5",
            "a913be62-665e-4b3e-b68b-eecaaf994c1b",
            "9915441f-59cf-4b91-a612-cc9bbda217b1",
            "8bd26bd0-5622-481c-b322-8dc3a3a2cc55",
            "fb2394b4-a6a3-4355-8cdf-0788856d894f",
            "59ec0cff-2938-445b-8f85-0772acb1fa3b",
            "a0687018-a64e-4b7b-a50f-5676b391e647",
            "cd23db1e-7a08-4853-ac81-f9dbe9406775",
            "d3f8c258-e502-4789-845f-c5665d58ba20",
            "09988d96-4a43-42ea-b41f-0544b866435a",
            "cb6206f9-a1c2-4999-a46b-7fd02d808b0f",
            "dcdbc4a7-6ded-42ef-a2e4-1d9acfe12199",
            "3e0f3fdc-6ec8-40a5-a59b-9e5a94536ff2",
            "82231708-374e-4628-8bd8-c7d4d81b4412",
            "df7c7a41-0582-48e5-af0d-45c67ba5c5bf",
            "399c465f-9ea0-4237-8433-774f19272d8a",
            "d11aa301-92de-4cab-ae35-ca5fa5a5dfb9",
            "f4c0b31d-d16c-4556-a8a5-e11c3e2b4264",
            "df4d6283-0801-439c-9fa1-ba83fc68d137",
            "4f4558a3-0de7-490a-85aa-bc91b884237e",
            "62888343-8c32-4138-a256-89a36b885ea2",
            "ec924d61-fd0d-40cd-8188-f5285c14750f",
            "b4e52078-539d-478d-be48-efab1b7d0229",
            "7863708a-09b9-4baf-987b-f30004e54282",
            "cd9564d3-88c9-4690-901d-d2bf5c5a92c9",
            "fb17553d-f822-4c78-b23f-fea66f98fdd4",
            "3c30ce65-0699-40ac-ba9e-4693703644ff",
            "95b3cbdf-010d-4451-867b-e172446c981d",
            "38655d42-2429-49d4-92bb-02ec38c0450d",
            "076badd8-92a8-4a77-a40c-02be93c2cf04",
            "4c4fdbfe-10e1-4081-8127-1acb98df5570",
            "810a6c50-dad6-49b5-860b-0f27d007911e",
            "f1beaafc-723e-4eeb-80cf-7c90a9851b86",
            "ddf356b1-02d1-4b9a-b915-606b71299b87",
            "dee85550-1db8-46c1-9346-48fad84b9a10",
            "6836ebcb-3530-4c49-8204-7e6fbcfe129d",
            "c8ed7673-a7e2-4d3b-b87b-8f2b4f1a0c2e",
            "22de6c35-0a2a-42da-82d6-af8f6f5e3796",
            "3e48e69d-aa68-4b8a-be0f-4d8146df937f",
            "350c86bd-a33a-43a2-885e-157c421a1fab",
            "666ec4a7-77e5-4040-9145-4f48050d3e64",
            "c6cb191f-0df5-468c-aa62-68f71e011cd9",
            "adfbfb02-85b7-4bd7-8d68-bd0a7babdfcc",
            "4096d3b1-fdb7-4c07-9b77-75bab81d8d15",
            "53a521bc-9d08-43a3-8de0-fb8c01c150b5",
            "05523eb8-a519-4a61-91b2-da84f84d2d45",
            "b8cd9ba5-e73e-428a-9920-f655899d00c9",
            "7c8a0c09-ae82-4679-8a40-fa34ffe5a9c5",
            "675dd55f-6526-4a8a-a4b9-e911f3147b31",
          ].includes(selectedTrainer?.trainer_id ?? "")
            ? " email, SMS messages, and personal trainer phone calls."
            : " email and SMS messages."}
        </Typography>
      </Container>
      <Dialog
        open={submitPaymentStatus === "failed"}
        onClose={() => {
          dispatch(resetPaymentAttempt());
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Something went wrong finalizing your account!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Check your payment details and try again. Contact
            support@trainwell.net if you continue to run into issues.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              dispatch(resetPaymentAttempt());
            }}
          >
            Check payment details
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={submitPaymentStatus === "account-error"}
        onClose={() => {
          dispatch(resetPaymentAttempt());
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Something went wrong finalizing your account!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please contact our support team for help at support@trainwell.net or
            go through the survey again from an incognito browser.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              dispatch(resetPaymentAttempt());
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={submitPaymentStatus === "succeeded-phone-exists"}
        onClose={() => {
          dispatch(resetPaymentAttempt());
        }}
        aria-labelledby="phone-dialog-title"
        aria-describedby="phone-dialog-description"
      >
        <DialogTitle id="phone-dialog-title">Phone number in use</DialogTitle>
        <DialogContent>
          <DialogContentText id="phone-dialog-description">
            This phone number is already in use. Try signing into the app or
            contact support@trainwell.net if you continue to run into issues.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              dispatch(resetPaymentAttempt());
            }}
          >
            Check phone number
          </Button>
        </DialogActions>
      </Dialog>
      {/* iHeart Pixel */}
      <img
        src="https://arttrk.com/pixel/?ad_log=referer&action=registration&pixid=666318fe-9985-4fba-a112-4ae321b43f77"
        width="1"
        height="1"
        style={{ border: "0" }}
      />
    </>
  );
}
