import * as Sentry from "@sentry/react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import type { TrainwellApi } from "@trainwell/api-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TrainwellError } from "../errors/TrainwellError";
import { useValidatePaymentMethod } from "./useValidatePaymentMethod";

type Props = {
  api: TrainwellApi;
  userId: string;
  onSuccess: () => void;
};

export function useAddPaymentMethod({ api, userId, onSuccess }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [clientSecret, setClientSecret] = useState<string | undefined | null>();
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string>();
  const { validateSetupIntent, validationMessage } = useValidatePaymentMethod({
    api: api,
    userId: userId,
    onSuccess: () => {
      onSuccess();
      setSubmitting(false);
      setClientSecret(undefined);
      setPaymentErrorMessage(undefined);
    },
    onFail: () => {
      setSubmitting(false);
      setClientSecret(undefined);
      setPaymentErrorMessage(undefined);
    },
  });

  // Get a client secret from the server
  // This securely connects a payment method to a client
  useEffect(() => {
    if (!userId || clientSecret || clientSecret === null || !open) {
      return;
    }

    setClientSecret(null);

    api.paymentMethods.createSetupIntent(userId).then((res) => {
      setClientSecret(res.client_secret);

      console.info("Stripe: got client secret", res.client_secret);
    });
  }, [userId, clientSecret, open]);

  async function addPaymentMethod(makeDefault: boolean) {
    // -
    // Validate current variables
    // -

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded
      console.warn("Stripe.js hasn't yet loaded");
      return;
    }

    if (submitting || !userId) {
      // General guards
      console.warn("Already submitting or no client");
      return;
    }

    if (!clientSecret) {
      enqueueSnackbar({
        message: "Error, please refresh the page and try again",
        variant: "error",
      });

      Sentry.captureException(
        new TrainwellError({
          name: "PaymentError",
          message: "Client secret not found",
          extraData: {
            user_id: userId,
          },
        }),
      );

      return;
    }

    setSubmitting(true);

    // -
    // Validate card
    // -

    const { error: validationError } = await elements.submit();

    if (validationError) {
      enqueueSnackbar({
        message: validationError.message,
        variant: "error",
      });

      Sentry.captureException(
        new TrainwellError({
          name: "PaymentValidationError",
          message: validationError.message,
          extraData: {
            user_id: userId,
          },
        }),
        {
          level: "info",
        },
      );

      setPaymentErrorMessage(validationError.message);

      setSubmitting(false);

      return;
    }

    const { error: setupError, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.host}/payment-method-validation?make_default=${encodeURIComponent(makeDefault)}`,
      },
      redirect: "if_required",
      clientSecret: clientSecret,
    });

    if (setupError) {
      console.error("Stripe: error confirming payment", setupError);

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to the client (for example, payment
      // details incomplete)
      enqueueSnackbar({
        message: setupError.message,
        variant: "error",
      });

      Sentry.captureException(
        new TrainwellError({
          name: "PaymentError",
          message: setupError.message,
          extraData: {
            user_id: userId,
            code: setupError.code,
          },
        }),
      );

      setPaymentErrorMessage(setupError.message);

      setSubmitting(false);

      return;
    } else if (setupIntent) {
      validateSetupIntent(setupIntent, makeDefault);
    } else {
      // Client will be redirected to PaymentMethodValidationPage.tsx
      // This page will handle the validation charge and setting the payment method as default
    }
  }

  return {
    addPaymentMethod,
    submitting,
    paymentErrorMessage,
    validationMessage,
  };
}
